<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <bread-crumb
            :breadcrumbs="[
              { label: 'Сотрудники', routeName: 'User' },
              { label: 'Просмотр' },
            ]"
          ></bread-crumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="page-title">Сотрудник</h3>
            <DetailListView
              :actions="actions"
              editRoute="UserEdit"
              :service="service"
              :columns="[
                {
                  label: 'Фамилия',
                  field: 'surname',
                  icon: 'pi pi-user',
                },
                {
                  label: 'Имя',
                  field: 'name',
                  icon: 'pi pi-user',
                },
                {
                  label: 'Отчество',
                  field: 'secondName',
                  icon: 'pi pi-user',
                },
                {
                  label: 'Дата рождения',
                  field: 'birthday',
                  icon: 'pi pi-calendar',
                  content: (data) => {
                    return Str.date(service.birthday);
                  },
                },
                {
                  label: 'Должность',
                  field: 'workPos',
                  icon: 'pi pi-briefcase',
                },
                {
                  label: 'Email',
                  field: 'email',
                  icon: 'pi pi-at',
                },
                {
                  label: 'Телефон',
                  field: 'number',
                  icon: 'pi pi-phone',
                },
              ]"
            >
            </DetailListView>

            <div v-if="service['tripList'] && service['tripList'].length > 0">
              <h4 class="page-title">Командировки</h4>
              <div v-for="trip in service['tripList']" :key="trip.id">
                <router-link
                  class="text-info link_underline"
                  :to="{ name: 'TripShow', params: { id: trip.id } }"
                >
                  {{ trip.where }} {{ Str.date(trip.start) }} -
                  {{ Str.date(trip.end) }}
                </router-link>
              </div>
            </div>

            <div
              v-if="
                service['vacationList'] && service['vacationList'].length > 0
              "
            >
              <h4 class="page-title">Отпуска</h4>
              <div v-for="trip in service['vacationList']" :key="trip.id">
                {{ Str.date(trip.dtStart) }} -
                {{ Str.date(trip.dtEnd) }}
              </div>
            </div>

            <div
              v-if="
                service['sickLeaveList'] && service['sickLeaveList'].length > 0
              "
            >
              <h4 class="page-title">Больничные</h4>
              <div v-for="trip in service['sickLeaveList']" :key="trip.id">
                {{ Str.date(trip.dtStart) }} -
                {{ Str.date(trip.dtEnd) }}
              </div>
            </div>

            <div v-if="service['taskList'] && service['taskList'].length > 0">
              <h4 class="page-title">Задачи</h4>
              <div v-for="task in service['taskList']" :key="task.id">
                <router-link
                  class="text-info"
                  :to="{ name: 'TaskShow', params: { id: task.id } }"
                >
                  {{ task.name }}
                </router-link>
                <span
                  :class="{
                    'text-danger': task.status == 0,
                    'text-warning': task.status == 1,
                    'text-success': task.status == 2,
                  }"
                  style="float: right"
                  >{{ statusName(task.status) }}</span
                >
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      header="Добавление отпуска"
      :modal="true"
      v-model:visible="displayVacationCreate"
    >
      В разработке...
    </Dialog>
    <Dialog
      header="Добавление больничного"
      :modal="true"
      v-model:visible="displaySickLeaveCreate"
    >
      В разработке...
    </Dialog>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import Dialog from "primevue/dialog";
import Str from "/src/utils/Str";
import UserService from "../services/UserService";
import DetailListView from "@/components/ui/DetailListView.vue";

export default {
  components: {
    BreadCrumb,
    DetailListView,
    Dialog,
  },
  data() {
    return {
      service: new UserService({
        expand: ["tripList", "vacationList", "sickLeaveList", "taskList"],
      }),
      Str: Str,
      displayVacationCreate: false,
      displaySickLeaveCreate: false,
      actions: [
        {
          label: "Добавить отпуск",
          icon: "pi pi-car text-success",
          command: () => {
            this.displayVacationCreate = true;
          },
        },
        {
          label: "Добавить больничный",
          icon: "pi pi-moon text-info",
          command: () => {
            this.displaySickLeaveCreate = true;
          },
        },
      ],
    };
  },
  methods: {
    statusName(status) {
      switch (status) {
        case 0:
          return "Не выполнена";
        case 1:
          return "Выполняется";
        case 2:
          return "Выполнена";
      }
    },
  },
  async created() {
    await this.service.show(this.$route.params.id);
  },
};
</script>
